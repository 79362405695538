import React, { useEffect } from 'react';
import './about.css';
import Hero from '../../components/Hero/Hero';

import heroBackground from '../../assets/services/hero.jpg';
import { about_data } from '../../utils';
import Button from '../../components/Button/Button';

import img1 from '../../assets/services/Strategizing for Success.jpg'
import img2 from '../../assets/services/Elevating your brand.jpg'
import founderImg from '../../assets/founder.jpg'



export default function About () {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div className='about'>
      <Hero background={heroBackground} url={'/contact'} title="About" />

      <main>
        <h2 className='title'> Blak Communications </h2>
        <p className='intro'> Welcome to Blak Communications—your trusted partner in public relations and digital brand management. We specialize in PR strategies that are designed to amplify your brand's voice and create lasting impressions. Whether you are a business owner, entrepreneur, or personal brand, we provide the tools and expertise needed to elevate your presence in an increasingly competitive world.
        </p>


        {
          about_data?.map((about, _) => {
            const { intro, title, content } = about;
            return (
              <section key={_}>
                <h3 className='big-tag'> {title} </h3>
                <p> {intro && intro} </p>
                {
                  content &&
                  content.map((each, idx) => {
                    const {tag, msg} = each;
                    return (
                      <aside key={idx}>
                        <h3 className='small-tag'> {tag} </h3>
                        <p> {msg} </p>
                      </aside>
                    )
                  })
                }
              </section>
            )
          })
        }


        <br /> <br />
        <h2 className='title'> About the founder </h2>
        
        <div className='founder'>
          <aside>
            <img src={founderImg} />
          </aside>
          <aside>
            <p className='intro'> 
              Mr. Micheal Okorodudu, the visionary behind Blak Communications, is a multifaceted individual with a passion for communication, creativity, and community development. Originally from Nigeria, Micheal currently resides in Regina, Saskatchewan, Canada, where he is furthering his education and expanding his professional endeavors.
              Beyond his role as the founder of Blak Communications, Micheal is a fashion model and a philanthropist. He has been actively involved in supporting new students and immigrants in Regina, helping them navigate their new environment through various charity initiatives and community outreach programs. His personal experience as an immigrant inspired him to establish Blak Communications, with the mission of providing strategic public relations services that empower brands and individuals alike.
              Micheal's commitment to blending creativity with strategic communication has positioned Blak Communications as a trusted partner for clients looking to enhance their public image
            </p>
          </aside>
        </div>


        <section className='twin-images'>
          <img src={img1} alt='' />
          <img src={img2} alt='' />
        </section>


        <br />
        <Button text="Get in touch" url="/contact" />
      </main>
    </div>
  )
}
