import React from 'react';
import './News.css';
import Hero from '../../components/Hero/Hero';
import heroBackground from '../../assets/services/newsHero2.jpg';
import { news_data } from '../../utils';
import bigVideo from '../../assets/news/big video.mp4';


export default function News() {
  return (
    <div className="news">
      <Hero background={heroBackground} title="News" />

      <main>
        {/* <h1> News </h1> */}
        <video src={bigVideo} preload="auto" loop controls className='video'/>

        {
          news_data?.map(each => {
            return (
              each?.type === 'text' ?
              <p> {each?.content} </p> :
              each?.type === 'list' ?
              <ul>
                {each.content.map(list => <li> {list} </li>)}
              </ul> :
              <img src={each.content} />
            )
          })
        }

      </main>
    </div>
  )
}
