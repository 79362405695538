import React from 'react';
import './button.css';
import { Link } from 'react-router-dom';

export default function Button ({ text, variant, url }) {
  return (
    <div>
      {
        url 
          ?
          <Link to={url}>
            <span 
              className='button-container' 
              onClick={() => { window.scrollTo(0, document.body.scrollHeight);}}>
                <div className={`button button-${variant}`}> 
                  {text ? text : 'Get Started!'} 
                </div>
            </span>
          </Link>
          :
          <span 
            className='button-container' 
            onClick={() => { window.scrollTo(0, document.body.scrollHeight);}}>
              <div className={`button button-${variant}`}> 
                {text ? text : 'Get Started!'} 
              </div>
          </span>
      }

    </div>
  )
}
