import React, { useState } from 'react';
import './create-blog.css';
import { useNavigate } from 'react-router-dom';

const YourComponent = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    author: '',
    description: '',
    content: '',
    title: '',
    image: null,
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: name === 'image' ? files[0] : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // error handling
    const {author, description, content, title} = formData;
    if(
      author.trim().length < 1 ||
      description.trim().length < 1 ||
      content.trim().length < 1 ||
      title.trim().length < 1
    ) {
      alert('Please fill all fields');
      return;
    };

    // Create a new FormData instance
    const data = new FormData();

    // Append fields to the FormData instance
    data.append('author', formData.author);
    data.append('description', formData.description);
    data.append('content', formData.content);
    data.append('title', formData.title);
    data.append('image', formData.image);


    setLoading(true)
    
    fetch('https://blog-backend-sjg5.onrender.com/api/blogs/createblog', {
      method: 'POST',
      body: data,
    })
    .then(response => response.json())
    .then(data => {
      setLoading(false);
      setFormData({
        author: '',
        description: '',
        content: '',
        title: '',
        image: null,
      })
      alert("Post Created Successfully!");
      navigate('/all-blogs');
    })
    .catch(error => {
      console.error('Error:', error);
      setLoading(false);
      alert('Error creating blog, please try again')
    });
    
  };





  return (
    <div className='create-blog'>
      <h2> Create New Blog Post </h2>

      <form onSubmit={handleSubmit}>
        <label>
          <p> Author </p>
          <input type="text" name="author" value={formData.author} onChange={handleInputChange} />
        </label>

        <label>
          <p> Title </p>
          <input type="text" name="title" value={formData.title} onChange={handleInputChange} />
        </label>

        <label>
          <p> Cover Photo </p>
          <input type="file" name="image" onChange={handleInputChange} />
        </label>

        <label>
          <p> Description </p>
          <input type="text" name="description" value={formData.description} onChange={handleInputChange} />
        </label>

        <label>
          <p> Content </p>
          <textarea name="content" value={formData.content} onChange={handleInputChange} rows={18} />
        </label>

        <button type="submit">{loading ? 'loading...' : 'submit'}</button>
      </form>
    </div>
  );
};

export default YourComponent;
