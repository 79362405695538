import React from 'react';
import './navbar.css';
import { FaPhoneFlip } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { FaFacebook, FaInstagram } from "react-icons/fa6";
import { useful_links } from '../../utils';
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import MobileNavbar from '../MobileNavbar/MobileNavbar';



export default function Navbar() {
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <div className='navbar'>
      {/* <div className='navbar-top'>
        <aside className='contacts'>
          <p> <IoMdMail /> lnn.travelsntours@gmail.com </p>
          <p> <FaPhoneFlip /> +2349064498091 </p>
        </aside>
        <aside className='socials'>
          <p> <a href="https://www.facebook.com/lnntravels?mibextid=2JQ9oc" rel="noreferrer" target='_blank'> <FaFacebook /> </a> </p>
          <p> <a href="https://www.instagram.com/lnn_travels?igsh=ZmJmMzZ5OW5qaTFu" rel="noreferrer" target='_blank'> <FaInstagram /> </a> </p>
        </aside>
      </div> */}

      <div className='navbar-bottom'>
        <aside>
          <Link to="/">
            <img src={logo} alt="L&N logos" className='logo' />
          </Link>
        </aside>

        <aside className='normal-nav'>
          <ul>
          {
            useful_links.map((_, idx) => {
              return (
                <li key={idx} style={{ color: pathname === _?.url ? '#ebc11d' : 'white'}}>
                  <Link to={_?.url}>
                    {_?.title}
                  </Link>
                </li>
              )
            })
          }
          </ul>
        </aside>
        <aside className='mobile-nav'>
          <MobileNavbar />
        </aside>
      </div>
    </div>
  )
}
