import React from 'react';
import './footer.css';
import { useful_links } from '../../utils';

import { FaFacebook, FaInstagram } from "react-icons/fa6";
import { FaPhoneFlip } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";

import { Link, useLocation } from 'react-router-dom';
import { FaPlusCircle } from "react-icons/fa";


export default function Footer() {
  const location = useLocation();
  const pathname = location.pathname;


  return (
    <div className='footer'>
      <section className='first-section'>
        <aside>
          <p> Regina, Saskatchewan, Canada </p>
          <p> ||| </p>
          <p> blakcomms@gmail.com </p>
          <p> 
            { pathname === '/blog' && 
                <div className='open-login'> 
                  <Link to="/all-blogs">
                    <FaPlusCircle /> 
                  </Link>
                </div>
              }
          </p>
        </aside>

        <aside className='follow-us'>
          <p className='title'> Follow Us </p>
          <p> <a href="https://www.facebook.com/blak.coms?mibextid=LQQJ4d" rel="noreferrer" target='_blank'> <FaFacebook /> </a> </p>
          <p> <a href="https://www.instagram.com/blak.corp" rel="noreferrer" target='_blank'> <FaInstagram /> </a> </p>
        </aside>
      </section>

      <section className='second-section'>
        <p> Copyright &copy; {new Date().getFullYear()} </p>
        <p> 
          <a href="https://www.instagram.com/oma_media_space__" rel='noreferrer' target='_blank'> 
            Developed by oma media
          </a>
        </p>
      </section>
    </div>
  )
}
