import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoutes = () => {
    const username = sessionStorage.getItem('username');
    const password = sessionStorage.getItem('password');
    
    let auth = username === 'blakcomms' && password === '246850';

    return(
        auth ? <Outlet/> : <Navigate to="/login"/>
    )
}

export default PrivateRoutes