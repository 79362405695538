
// import logistics_tall_icon from './assets/home/logisitics_tall.jpg';
// import travel_tall_icon from './assets/home/travel_tall.jpg';
// import travel_icon_1 from './assets/home/travel1.jpg';
// import travel_icon_2 from './assets/home/travel2.jpg';



import testimonial1 from './assets/home/testimonials/testimonial-1.jpg'
import testimonial2 from './assets/home/testimonials/testimonial-2.jpg'
import testimonial3 from './assets/home/testimonials/testimonial-3.jpg'

import new1 from './assets/news/news1.jpg'
import new2 from './assets/news/news2.jpg'
import new3 from './assets/news/news3.jpg'
import new4 from './assets/news/news4.jpg'
import new5 from './assets/news/news5.jpg'

import service1 from './assets/services/Building your biz.jpg'
import service2 from './assets/services/Elevating your brand.jpg'
import service3 from './assets/services/Presentations that seal.jpg'
import service4 from './assets/services/Reach your audience.jpg'
import immigrantGiftImg from './assets/services/immigrant gift .jpg'

import staff1 from './assets/staff1.jpg'
import staff2 from './assets/staff2.jpg'


export const services = [
  {
    title: 'Public Relations',
    msg: 'We craft strategic communication plans that help you connect with the right audience, manage your brand reputation, and gain positive media coverage.'
  },
  {
    title: 'Social media management',
    msg: 'Stay ahead of the digital curve with our expert social media management services. We handle everything from content creation to engagement, so your platforms remain active and impactful.'
  },
  {
    title: 'Event Promotion',
    msg: 'From planning to execution, we help you promote your brands events in a way that draws attention and sparks interest.'
  },
  {
    title: 'Content Creation',
    msg: ' We produce creative and compelling content that tells your story in an authentic and engaging way, ensuring your brand stands out in the crowded digital space.'
  },
];


export const why_chose_us = [
  {
    title: 'Tailored Solutions',
    msg: 'We understand that every brand is unique. That’s why we offer customized services that are designed to meet your specific needs and objectives.'
  },
  {
    title: 'Experienced Team',
    msg: 'Our team of seasoned professionals brings a wealth of knowledge and a passion for innovation. We work with you to ensure that your message is not just heard but remembered.'
  },
  {
    title: 'Client-centered approach',
    msg: 'At Blak Communications, we believe in collaboration. We listen, we strategize, and we execute—always with your goals in mind.'
  },
];


export const testimonials_data = [
  {
    title: 'CEO, Bencarthypaul Green Travel Ventures',
    name: 'Osindeinde Oluwasegun John',
    img: testimonial1,
    msg: "Working with Blak communications has been a game-changer for our business! Their digital marketing strategy doubled our website traffic in just a few months, and the leads have been pouring in ever since. Their team's expertise and commitment to driving real results are unmatched. We couldn't be happier!"
  },
  {
    title: 'Manager, Mishcan Consulting Firm',
    name: 'Emily R.',
    img: testimonial2,
    msg: "We were struggling to get our brand noticed online, but thanks to Blak communications, our social media presence has exploded! They developed a custom campaign that increased our engagement by 300%. Their team is incredibly creative and professional—exactly what we needed to take our business to the next level."
  },
  {
    title: 'Marketing Director, EcoGrow Industries',
    name: 'David M.',
    img: testimonial3,
    msg: "I've worked with several marketing agencies before, but Blak communications is hands down the best. They took the time to understand our goals and delivered a comprehensive media strategy that boosted our online visibility and sales. The ROI has been fantastic, and we’ve seen steady growth since partnering with them"
  },
]


export const useful_links = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Services',
    url: '/services',
  },
  {
    title: 'About',
    url: '/about',
  },
  {
    title: 'News',
    url: '/news',
  },
  {
    title: 'Blog',
    url: '/blog',
  },
  {
    title: 'Contact',
    url: '/contact',
  },
]


export const services_data = [
  {
    img: immigrantGiftImg,
    msg: "Philanthropy: At Blak Communications, philanthropy is more than just a commitment—it's a tradition we practice and respect. Our team is deeply invested in the well-being and success of new students and immigrants in Regina, Saskatchewan. As part of our mission to give back, we regularly organize and support initiatives that provide essential resources, such as clothing, household items, and winter gear, to help newcomers settle into their new environment. Through these charitable efforts, we strive to foster a welcoming community, making sure every individual has the support they need to thrive. Whether it's through donation drives, outreach programs, or direct support, we are proud to contribute to the success and comfort of those starting their journey in Canada. We invite partners, sponsors, and volunteers to join us in this effort as we continue making a tangible difference in the lives of new immigrants and students. This not only emphasizes the philanthropic services but also invites collaboration, reinforcing your community-driven goals."
  },
  {
    img: service1,
    msg: 'Public Relations: We craft strategic communication plans that help you connect with the right audience, manage your brand reputation, and gain positive media coverage.'
  },
  {
    img: service2,
    msg: 'Social Media Management: Stay ahead of the digital curve with our expert social media management services. We handle everything from content creation to engagement, so your platforms remain active and impactful.'
  },
  {
    img: service3,
    msg: 'Event Promotion: From planning to execution, we help you promote your brand’s events in a way that draws attention and sparks interest.'
  },
  {
    img: service4,
    msg: 'Content Creation: We produce creative and compelling content that tells your story in an authentic and engaging way, ensuring your brand stands out in the crowded digital space.'
  },
]


export const about_data = [
  {
    title: 'About us',
    intro: "At Blak Communications, we are dedicated to helping brands and individuals build powerful and authentic connections with their audience. Located in Regina, Saskatchewan, we specialize in Public Relations, Social Media Management, and Content Creation to help businesses and personal brands establish a credible and impactful online presence. \n Our core philosophy revolves around the belief that every brand has a unique story to tell, and it's our job to craft that story in a way that resonates with your target audience. Whether it's through strategic communication, social media campaigns, or brand promotion, we work closely with our clients to ensure their goals are met with professionalism, creativity, and precision. \n Our team is composed of experienced PR professionals, creative strategists, and digital marketers who are passionate about pushing boundaries and delivering exceptional results. From startups to well-established companies, our expertise spans across different industries, including fashion, travel, technology, education, and more. \n At Blak Communications, we are not just about building brands—we are about fostering relationships that last. Our services include media relations, social media management, crisis communication, event promotion, and content development tailored to meet the evolving needs of the digital landscape. \n With a global reach and a focus on the local community, we strive to help our clients not only meet their immediate business needs but also to grow and thrive in a constantly changing world. Trust Blak Communications to help you navigate the complexities of public perception, while you focus on what you do best."
  },
  {
    title: 'our services',
    content: [
      {
        tag: 'Public relations',
        msg: "We craft strategic communication plans that help you connect with the right audience, manage your brand reputation, and gain positive media coverage."
      },
      {
        tag: 'Social media management',
        msg: 'Stay ahead of the digital curve with our expert social media management services. We handle everything from content creation to engagement, so your platforms remain active and impactful.'
      },
      {
        tag: 'Event Promotion',
        msg: "From planning to execution, we help you promote your brand's events in a way that draws attention and sparks interest."
      },
      {
        tag: "Content Creation",
        msg: "We produce creative and compelling content that tells your story in an authentic and engaging way, ensuring your brand stands out in the crowded digital space."
      }
    ]

  },
  {
    title: 'WHY CHOOSE BLAK COMMUNICATIONS?',
    content: [
      {
        tag: 'Experience',
        msg: "We understand that every brand is unique. That's why we offer customized services that are designed to meet your specific needs and objectives"
      },
      {
        tag: 'Experienced Team',
        msg: ' Our team of seasoned professionals brings a wealth of knowledge and a passion for innovation. We work with you to ensure that your message is not just heard but remembered.'
      },
      {
        tag: 'Client-Centered Approach',
        msg: "At Blak Communications, we believe in collaboration. We listen, we strategize, and we execute—always with your goals in mind."
      },
      {
        tag: 'Proven Results',
        msg: "We take pride in delivering measurable results that help you grow, from increasing engagement to enhancing your brand’s credibility."
      },
    ]

  }
]


export const guides_data = [
  {
    title: 'Graphic Designer',
    name: 'Folafunmi John Fakuade',
    img: staff1,
  },
  {
    title: 'Social media marketing manager',
    name: 'Emmanuel Ifeanyichukwu Omali',
    img: staff2,
  }
]


export const news_data = [
  {
    type: 'text',
    'content': "Philanthropy: Supporting New Students and Immigrants in Regina, Saskatchewan. At Blak Communications, our commitment to philanthropy extends beyond words—it is a deeply rooted tradition that we practice with passion and purpose. As a public relations agency dedicated to community development and corporate social responsibility, we have made it our mission to support and empower new students and immigrants settling in Regina, Saskatchewan, Canada. For us, philanthropy is not just a service but an essential pillar of our identity as a business, grounded in compassion, empathy, and the drive to make a lasting difference in people’s lives."
  },
  {
    type: 'text',
    'content': "Why We Focus on Newcomers: Moving to a new country can be both an exciting and daunting experience, especially for students and immigrants trying to adapt to an unfamiliar environment. Regina, Saskatchewan, with its diverse community, is a welcoming city, but many newcomers face unique challenges as they transition into life here. These challenges include navigating the climate, finding suitable accommodation, securing basic needs, and integrating into their new community."
  },
  {
    type: 'image',
    content: new1
  },
  {
    type: 'text',
    'content': "At Blak Communications, we understand the difficulties that come with starting fresh in a foreign country. Many of us have gone through the same journey, and as part of our core mission, we aim to ease the burden for new students and immigrants through dedicated support initiatives that help them settle into their new lives smoothly and confidently."
  },
  {
    type: 'text',
    'content': "How We Give Back: Blak Communications takes great pride in organizing and participating in community outreach programs designed to provide essential resources to newcomers. From donation drives to winter gear distributions, we've developed various initiatives aimed at ensuring no one is left behind during their transition into Canadian life."
  },
  {
    type: 'list',
    'content': [
      "Donation Drives for Essential Needs: One of our most impactful services is organizing donation drives to collect clothing, household items, and other essential goods for new students and immigrants. These drives are held regularly, especially during critical periods such as the winter months when individuals need warm clothing and gear to withstand the harsh Saskatchewan weather.",
      "	Winter Gear Distribution: Recognizing the severity of Canadian winters, we actively seek donations of winter gear, such as coats, boots, hats, scarves, and gloves, to ensure newcomers have access to the right clothing to keep them warm and safe. For those arriving from warmer climates, the transition can be especially difficult, and we aim to bridge that gap with comprehensive support.",
      "Household Essentials: In addition to clothing, we also work to provide basic household items such as blankets, bedding, kitchen utensils, and other necessary items that help newcomers settle into their new homes with comfort and ease.",
      "Cooked Meal Programs: Understanding the financial strain many new students and immigrants face, we often collaborate with local volunteers, community groups, and businesses to provide free cooked meals during our events. These meal programs not only help meet immediate needs but also foster a sense of community and belonging.",
    ]
  },
  {
    type: 'image',
    content: new2
  },
  {
    type: 'text',
    'content': "Our Year-Round Philanthropic Events: We regularly hold events throughout the year, focusing on specific needs of new students and immigrants based on the season and current community challenges. Some of our key events include:"
  },
  {
    type: 'list',
    'content': [
      "Back-to-School Drives: For new students arriving in Regina to pursue their education, we provide school supplies, books, and other educational materials. We also offer mentorship and guidance, helping students integrate into their academic and social environments.",
	    "Holiday Season Giving: During the holiday season, Blak Communications organizes special giving events, providing gifts and food packages to families and individuals who may otherwise feel isolated during this time. We believe that the spirit of giving can go a long way in making immigrants feel welcome during their first holiday season in Canada.",
      "Winter Preparation Drives: As the cold months approach, we shift our focus to preparing new residents for the harsh Saskatchewan winter. Our winter preparation drives focus on providing essential winter clothing and tips on staying safe and warm during this period.",
      "Emergency Support for Immigrants: In times of crisis, such as during the COVID-19 pandemic, we expanded our efforts to support those affected by job losses and financial difficulties. We offered resources such as grocery packages and emergency financial assistance to help those who needed it most.",
    ]
  },
  {
    type: 'image',
    content: new3
  },
  {
    type: 'text',
    content: "Collaborative Efforts & Sponsorship Opportunities: At Blak Communications, we know that collective action is the key to creating lasting change. That's why we actively seek out partnerships with local businesses, community organizations, and individual sponsors who share our vision of making Regina a more inclusive and supportive environment for all its residents. We invite donors and sponsors to join hands with us in supporting these vital philanthropic initiatives. Your contributions—whether financial or in-kind donations—play a crucial role in the success of these programs and directly impact the lives of those who need it most. Every donation goes directly toward purchasing essential items, organizing events, and ensuring the continuous support of newcomers throughout the year."
  },
  {
    type: 'image',
    content: new4
  },
  {
    type: 'text',
    content: "A Tradition We Respect and Practice. Philanthropy is not just a service at Blak Communications—it’s part of our DNA. Our team, led by founder Michael Okorodudu, is dedicated to ensuring that the spirit of giving and community service remains central to everything we do. We firmly believe that giving back to the community is an obligation that every business must embrace, especially when it comes to helping those who are in the process of building a better life for themselves. We don’t just talk about making a difference—we take action. Our consistent and impactful involvement in charitable initiatives has earned us a reputation as a trusted and compassionate ally for newcomers in Regina. Every act of generosity from our supporters is amplified through our carefully planned events and outreach programs, helping to strengthen our community, one person at a time."
  },
  {
    type: 'text',
    content: "Join Us in Making a Difference. As we continue to expand our philanthropic outreach, we are always on the lookout for volunteers, sponsors, and community partners to help us achieve our goals. Whether you’re a local business owner, a student, or simply someone looking to give back, there’s a place for you in our efforts. By working together, we can provide the support and resources that new students and immigrants need to thrive. Whether it's donating winter gear, providing financial assistance, or helping with food distribution, your contribution will make a lasting impact."
  },
  {
    type: 'image',
    content: new5
  },
  {
    type: 'text',
    content: "For more information on how you can get involved or to learn more about our upcoming charitable events, please feel free to contact us at +1 (306) 550-6583. Let's work together to ensure that every newcomer in Regina feels supported and valued as they begin this new chapter of their lives."
  },
]





