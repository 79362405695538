import React, { useEffect } from 'react';
import './home.css';
import Hero from '../../components/Hero/Hero';

import heroBackground2 from '../../assets/sky2.jpg';
import Button from '../../components/Button/Button';
import { 
  why_chose_us, 
  core_features_data, 
  packages_data, 
  guides_data,
  testimonials_data,
  services,
} from '../../utils';
import { FaLocationDot, FaCalendarDays } from "react-icons/fa6";
import { FaUserAlt, FaStar } from "react-icons/fa";
import { FaUserGraduate } from "react-icons/fa6";
import Contact from '../../components/Contact/Contact';




export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='home'>
      <Hero 
        background={heroBackground2} 
        title="Unlock your business potential with BLAK communications"
        subtitle="We help small business owners crystalize their vision, manage business and also manage projects"
      />

      {/* <div>
        {
          core_features_data.map((each, _) => {
            const { tag, title, msg, img, img1, img2 } = each;
            return (
              <div className='core-features' key={_}>
                <section className='tall-image'>
                  <img src={img} alt='' />
                </section>

                <section className='content'>
                  <p className='tag'> {tag.toUpperCase()} </p>
                  <h2 className='title'> {title} </h2>
                  <p className='msg'> {msg} </p>
                  <aside className='twin-images'>
                    <div> <img src={img1} alt="" /> </div>
                    <div> <img src={img2} alt="" /> </div>
                  </aside>

                  <Button url={'/contact'} />
                </section>
              </div>
            )
          })
        }
      </div> */}


      <div className='services'>
        <h2 className='tag'> Services </h2>
        <div className='container'>
          {
            services?.map((each, _) => {
              return (
                <div key={_} className='single-reason'>
                  <h2 className='title'> {each.title} </h2>
                  <p className='msg'> {each.msg} </p>
                </div>
              )
            })
          }
        </div>
      </div>



      <div className='why-choose-us-container'>
        <div className='why-choose-us'>
          <h2 className='tag'> WHY CHOOSE US ? </h2>
          <div className='container'>
            {
              why_chose_us?.map((each, _) => {
                return (
                  <div key={_} className='single-reason'>
                    <FaUserGraduate />
                    <h2 className='title'> {each.title} </h2>
                    <p className='msg'> {each.msg} </p>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>







      
      {/* <div className='packages'> 
        <p className='tag'> PACKAGES </p>
        <h2 className='title'> PERFECT TOUR PACKAGES </h2>

        <div className='packages-container'>
          {
            packages_data.map((each, _) => {
              return (
                <div key={_} className='package'>
                  <img src={each} alt="" />
                  <aside className='content'>
                    <aside className='info'>
                      <p className='location'> <FaLocationDot /> Thailand </p>
                      <p> <FaCalendarDays /> 3 days </p>
                      <p> <FaUserAlt /> 2 persons </p>
                    </aside>

                    <h2> Discover Amazing places of the world with us </h2>

                    <aside className='foot'>
                      <p> <FaStar /> 4.5 (250) </p>
                      <p> $350 </p>
                    </aside>
                  </aside>
                </div>
              )
            })
          }
        </div>
      </div> */}




      <div className='guides'> 
        <p className='tag'> TEAM </p>
        <h2 className='title'> OUR AWESOME TEAM </h2>

        <div className='guides-container'>
          {
            guides_data.map((each, _) => {
              return (
                <div key={_} className='guide'>
                  <img src={each.img} alt="" />
                  <aside className='content'>
                    <p> {each.name} </p>
                    <p> {each.title} </p>
                  </aside>
                </div>
              )
            })
          }
        </div>
      </div>







      <div className='testimonials'> 
        <p className='tag'> TESTIMONIALS </p>
        <h2 className='title'> WHAT SAY OUR CLIENTS </h2>

        <div className='testimonials-container'>
          {
            testimonials_data.map((each, _) => {
              return (
                <div key={_} className='testimonial'>
                  <img src={each.img} alt="" />
                  <aside className='content'>
                    <p> {each.name} </p>
                    <p> {each.msg} </p>
                    <p> {each.title} </p>
                  </aside>
                </div>
              )
            })
          }
        </div>
      </div>


      <Contact />

    </div>
  )
}
