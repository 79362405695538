import React, { useEffect } from 'react';
import './services.css';
import Hero from '../../components/Hero/Hero';

import heroBackground from '../../assets/services/hero.jpg';
import { services_data } from '../../utils';
import Button from '../../components/Button/Button';



export default function Services() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='services'>
      <Hero background={heroBackground} url={'/contact'} title="Services" />

      <main>
        <h2 className='title'> Blak Communications </h2>
        <p> Welcome to Blak Communications—your trusted partner in public relations and digital brand management. We specialize in PR strategies that are designed to amplify your brand's voice and create lasting impressions. Whether you are a business owner, entrepreneur, or personal brand, we provide the tools and expertise needed to elevate your presence in an increasingly competitive world.
        </p>


        {
          services_data?.map((service, _) => {
            return (
              <aside>
                <div>
                  <img src={service.img} alt=''/>
                </div>
                <div>
                  <p> {service.msg} </p>
                </div>
              </aside>
            )
          })
        }

        <br />
        <Button text="Get in touch" url="/contact" />
      </main>
    </div>
  )
}
